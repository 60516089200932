@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@400;700&display=swap');

h1,
h2,
h3,
h4,
h4,
h5,
h6,
p,
a,
body,
div {
  font-family: Poppins;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #fe7a15;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #0191b4;
}

.my-results-center::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

.my-results-center::-webkit-scrollbar-thumb {
  background: #fe7a15;
}

/* Handle on hover */

.my-results-center::-webkit-scrollbar-thumb:hover {
  background: #0191b4;
}

.hero-bg {
  background-image: linear-gradient(359deg, transparent, rgba(0, 0, 0, 0.46) 65%, transparent 73%, rgba(0, 0, 0, 0.7)),
    url(/images/sunset-1139293.jpg);
}

@media (min-width: 1024px) {
  .hero-bg {
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.0354516807) 0%, rgba(0, 0, 0, 0.7) 100%),
      url(/images/sunset-1139293.jpg);
  }
}

.brand-name {
  font-family: 'Amita', 'Poppins', sans-serif;
}

.read-more::before {
  content: '';
  display: inline-block;
  position: relative;
  top: 9px;
  left: -10px;
  width: 30px;
  height: 30px;
  background-image: url(http://gfxpartner.com/Frolic/images/arrow-link.png);
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.testimonialBlockQuote {
  margin: 0 0 -50px;
  padding: 30px 50px 65px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.testimonialBlockQuote::before,
.testimonialBlockQuote::after {
  font-family: 'FontAwesome';
  content: '\201C';
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}

.testimonialBlockQuote::before {
  top: 5px;
  left: 20px;
}

.testimonialBlockQuote::after {
  content: '\201D';
  right: 20px;
  bottom: 20px;
}

.slick-slide img {
  display: inline-block !important;
}

.social-icons-footer::before {
  content: '';
  width: 75%;
  height: 1px;
  background: #ecf4f8;
  position: absolute;
  top: 15px;
}

.carousel img {
  width: 6rem !important;
}

.reviews {
  width: 100%;
  padding: 0 30%;
}

@media (max-width: 640px) {
  .reviews {
    width: 100%;
    padding: 0 6.5%;
  }
  .social-icons-footer::before {
    width: 0;
  }
}

.recommendations-container::before,
.recommendations-row::before {
  display: table;
  content: '';
}

.city-page-grid {
  grid-template-columns: 65% repeat(1, minmax(0, 1fr));
}

.solo-grid {
  grid-template-columns: 45% repeat(1, minmax(0, 1fr));
}

.single-n-destinations-blog {
  grid-template-columns: 35% repeat(1, minmax(0, 1fr));
}

.blog-main-page-grid {
  grid-template-columns: 208px repeat(1, minmax(0, 1fr));
}

.map-height {
  height: 36rem;
}

@media (max-width: 1024px) {
  .city-page-grid {
    grid-template-columns: 50% repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .city-page-grid,
  .blog-main-page-grid,
  .solo-grid,
  .single-n-destinations-blog {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .map-height {
    height: 24rem;
  }
}

.rec-arrow {
  background-color: transparent !important;
  min-width: 2rem !important;
  border: none !important;
  box-shadow: none !important;
}

.rec-slider-container {
  text-align: center !important;
}

.rec-carousel-wrapper {
  background-color: #fef8d7 !important;
  padding: 1rem !important;
  border-radius: 1rem;
}

.rec-dot {
  background-color: transparent !important;
  box-shadow: 0 0 1px 1px !important;
}

.rec-slider-container {
  margin: 0 !important;
}

.rec-arrow {
  width: 2rem !important;
  height: 2rem !important;
}

.places-to-visit p {
  margin-bottom: 1rem;
}

li p {
  display: inline;
}
